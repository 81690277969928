import React from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import AboutDescriptive from "../components/About/AboutDescriptive"
import Beliefs from "../components/About/Beliefs"
import { Hero } from "../components/Partials/Hero"
import AppContainer from "../styles/AppContainer"
import Sidebar from "../components/Sidebar/Sidebar"
import VerticalSpacer from "../components/Partials/VerticalSpacer"
import { colors } from "../styles/colors"
import useToggleFooterColor from "../hooks/useToggleFooterColor"

const About = ({ data }) => {
    const { heading, paragraphs: aboutParagraph, beliefs: belief, meta } = get(
        data,
        "allAboutJson.edges[0].node",
        {}
    )
    const { copy: headingCopy } = heading
    const { image: metaImage } = meta

    const doesCTAExist = !!headingCopy.slug

    useToggleFooterColor({ useTan: true })

    return (
        <>
            <SEO
                title="About | Descriptive"
                description="A select list of clients we've been fortunate to work with over the years."
                image={metaImage}
            />
            <Helmet bodyAttributes={{ class: "about-page" }} />
            <AppContainer>
                <Sidebar copy="About" fontColor={colors.persianIndigo} />
                <Hero
                    heading={headingCopy}
                    ctaExists={doesCTAExist}
                    isTan
                    isAbout
                    hasLine
                />
                <VerticalSpacer size="xl" />
                <AboutDescriptive paragraphs={aboutParagraph} />
                <Beliefs beliefs={belief} />
            </AppContainer>
        </>
    )
}
export default About

export const query = graphql`
    query {
        allAboutJson {
            edges {
                node {
                    meta {
                        image {
                            src
                            alt
                        }
                    }
                    heading {
                        copy {
                            header
                        }
                    }
                    paragraphs {
                        id
                        paragraph
                    }
                    beliefs {
                        id
                        number
                        header
                        description
                    }
                }
            }
        }
    }
`
