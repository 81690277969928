import React from "react"
import styled from "@emotion/styled"
import { Paragraph } from "../Partials/Typography"
import RightContainer from "../Partials/RightContainer"

const AboutDescriptive = ({ paragraphs }) => {
    return (
        <>
            <ParagraphContainer>
                <RightContainer>
                    <Paragraphs>
                        {paragraphs.map(({ id, paragraph }) => (
                            <>
                                <Paragraph
                                    key={id}
                                    copy={paragraph}
                                    isAbout
                                    isTan
                                />
                            </>
                        ))}
                    </Paragraphs>
                </RightContainer>
            </ParagraphContainer>
        </>
    )
}

export default AboutDescriptive

const ParagraphContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 8rem;
`
const Paragraphs = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
`
