import React from "react"
import styled from "@emotion/styled"
import { HeaderThree, Paragraph, P } from "../Partials/Typography"
import RightContainer from "../Partials/RightContainer"
import VerticalSpacer from "../Partials/VerticalSpacer"
import { colors } from "../../styles/colors"
import { deviceMinW } from "../../styles/mediaQueries"
import { spacer } from "../../styles/sizes"
import { fontSize } from "../../styles/fonts/fontStyle"

const Beliefs = ({ beliefs }) => {
    return (
        <>
            <BeliefsContainer>
                <RightContainer>
                    <List>
                        {beliefs.map(({ id, number, header, description }) => (
                            <>
                                <ItemWrapper key={id}>
                                    <NumberWrapper>
                                        <RotateWrapper>
                                            <Number>{number}</Number>
                                        </RotateWrapper>
                                    </NumberWrapper>
                                    <BeliefWrapper>
                                        <BeliefHeader
                                            isTan
                                            copy={header}
                                            isAbout
                                        />
                                        <VerticalSpacer size="xxs" />
                                        <Paragraph isTan copy={description} />
                                    </BeliefWrapper>
                                </ItemWrapper>
                            </>
                        ))}
                    </List>
                </RightContainer>
            </BeliefsContainer>
        </>
    )
}

export default Beliefs

const BeliefsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`
const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 6rem;

    /* justify-content: space-between; */

    &:last-of-type {
        padding-bottom: 0;
    }

    @media ${deviceMinW.mobileXL} {
        flex-direction: row;
        align-items: center;
    }
`
const List = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
`
const BeliefWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 90%;
    flex-grow: 1;
`

const NumberWrapper = styled.div`
    flex-basis: 10%;
    min-width: ${spacer.l};
    flex-grow: 2;

    @media ${deviceMinW.mobileXL} {
        font-size: ${fontSize.greatPrimer};
        flex-basis: 20%;
        min-width: ${spacer.xl};
    }
`
const RotateWrapper = styled.div`
    text-align: left;
    padding-bottom: 2rem;

    @media ${deviceMinW.mobileXL} {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        text-align: right;
        padding-bottom: 0;
    }
`

const Number = styled(P)`
    color: ${colors.persianIndigo};
    margin-bottom: 0;
    line-height: 1;
    font-size: ${fontSize.greatPrimer};
`

const BeliefHeader = styled(HeaderThree)``
